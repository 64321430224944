//USING THIS TO HAVE REM LIKE MANAGMENT OF HEADER-SIZES WHICH OFTEN HAVE A DIFFERENT FONT
const remCalc = function(size) {
  if (isNaN(size)) {
    size = parseInt(size);
  }
  return size / typography.size.replace('px', '') + 'rem';
};

//COLORS VARIABLES
const colors = {
  white: '#FFF',
  black: '#111',
  red: '#E82A2A',
  green: '#69a24c',
  yellow: '#FFCB37',
  font: '#222',
  primary: '#53473E',
  primaryLight: '#79826c',
  primaryDark: '#424A35',
  secondary: '#2C4E5D',
  discount: '#b44e3c',
  link: '#ce3c8a',
  border: '#EEE',
  borderLight: '#F1F1F1',
  borderDark: '#333',
  borderInput: '#AAA',
  lightgrey: '#f6f6f6',
  vanillaWhite: '#fbf8f4',
  grey: '#565558',
  error: '#ff3131',
  success: '#69a24c',
  greys: [
    '#F9F9F9',
    '#F1F1F1',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#3B3B3B'
  ]
};
colors.buy = colors.primary;
colors.backgroundLight = colors.primary;
colors.backgroundDark = colors.secondary;
colors.inStock = '#339600';
colors.outOfStock = colors.red;

//FONTS
const font = {
  primary: 'Avenir',
  secondary: 'Avenir'
};

const weights = {
  ultra: 100,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700
};

//TYPOGRAPHY
let typography = {
  fontFamily: font.primary,
  fontWeight: weights.normal,
  size: '14px',
  letterSpacing: '1.2px',
  paragraph: {
    fontSize: '1rem',
    lineHeight: '1.7em',
    marginBottom: '1rem'
  },
  header: {
    fontFamily: font.secondary,
    textTransform: 'uppercase',
    fontWeight: weights.bold,
    letterSpacing: '1px',
    secondFont: {
      fontFamily: font.secondary,
      fontWeight: weights.normal
    },
    secondColor: {
      color: colors.secondary
    }
  }
};

//HEADER FONTSIZES h1-h5 (sm & md & lg & xl)
typography.header.fontSize = [
  '__',
  [remCalc(26), remCalc(31), remCalc(41), remCalc(52)],
  [remCalc(20), remCalc(24), remCalc(24), remCalc(28)],
  [remCalc(18), remCalc(20), remCalc(20), remCalc(20)],
  [remCalc(14), remCalc(16), remCalc(16), remCalc(16)],
  [remCalc(12), remCalc(12), remCalc(12), remCalc(12)]
];

const config = {
  storeName: process.env.REACT_APP_SHOP_NAME || 'Brusletto',
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/bookmarks'
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/stories',
      id: process.env.REACT_APP_BLOG_ID || 155,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: process.env.REACT_APP_BRAND_ID || 0
    },
    myPages: {
      showNewsletter: true,
      showMemberCode: false,
      showAccessCode: false
    }
  },
  settings: {
    buyButtons: { SE: true, NO: true, INT: false, DE: true },
    germanVatContentId: 71,
    channelSelector: true,
    siteContentId: process.env.REACT_APP_SITECONTENT_ID || 51
  }
};

const general = {
  maxWidth: '1600px',
  maxWidthText: '1000px',
  pagePadding: {
    lg: '1rem',
    md: '1rem',
    sm: '1rem'
  },
  hallmarkBorder: '2px solid ' + colors.borderDark,
  radius: '3px',
  channelSelector: false
};

//BUTTON
const button = {
  background: colors.primary,
  backgroundSecondary: colors.secondar,
  color: colors.white,
  colorSecondary: colors.white,
  fontFamily: font.secondary,
  fontWeight: weights.medium,
  fontSize: typography.header.fontSize[4],
  ListFontSize: typography.header.fontSize[5],
  textTransform: 'uppercase',
  letterSpacing: '1.5px'
};

//AREAS

//TOPBAR
const topBar = {
  height: {
    lg: 40,
    md: 40,
    sm: 40,
    scroll: 0
  },
  color: colors.white,
  background: colors.primary,
  fontSize: '0.85rem',
  fontWeight: weights.medium,
  usp: {
    fontSize: remCalc(12),
    fontWeight: weights.bold
  }
};

//DESKTOP HEADER
const desktopHeader = {
  height: {
    lg: 70,
    md: 70,
    scroll: 70
  },
  search: {
    height: {
      lg: 30,
      md: 30
    }
  },
  logo: {
    width: {
      lg: '200px',
      xl: '200px',
      scroll: '200px'
    }
  },
  background: colors.white,
  color: colors.font,
  nav: {
    fontSize: [remCalc(13), remCalc(13)],
    fontWeight: weights.medium,
    padding: '0.7rem',
    barHoverColor: colors.primary,
    subMenu: {
      lv2: {
        fontSize: '1.1rem'
      },
      lv3: {
        fontSize: '0.9rem'
      }
    },
    height: {
      lg: 70,
      md: 70,
      scroll: 70
    },
    useSubMenuImage: true
  }
};

//MOBILE HEADER
const mobileHeader = {
  height: {
    xs: 60,
    sm: 60,
    scroll: 60
  },
  search: {
    height: {
      xs: 60,
      sm: 60
    }
  },
  logo: {
    width: '120px'
  },
  background: colors.white,
  color: colors.font,
  nav: {
    lv1: {
      fontSize: remCalc(17),
      fontWeight: typography.header.fontWeight,
      fontFamily: typography.header.fontFamily,
      lineHeight: '3.4em',
      textTransform: 'uppercase'
    },
    lv2: {
      fontSize: '0.9rem',
      lineHeight: '3.2em'
    },
    lv3: {
      fontSize: '0.8rem',
      lineHeight: '3em'
    },
    activeColor: colors.primaryDark,
    selectedColor: colors.primary
  }
};

//HEADER HEIGHTS
const header = {
  height: {
    lg: topBar.height.lg + desktopHeader.height.lg,
    md: topBar.height.md + desktopHeader.height.md,
    sm: topBar.height.md + mobileHeader.height.sm,
    xs: topBar.height.md + mobileHeader.height.xs,
    scroll: topBar.height.scroll + desktopHeader.height.scroll
  },
  scrollBreak: 50
};

//LOGO
const logo = {
  size: 500,
  aspect: '100:40'
};

//CAMPAIGN BAR
const campaignBar = {
  primary: {
    background: colors.backgroundLight,
    color: colors.font
  },
  secondary: {
    background: colors.secondary,
    color: colors.white
  }
};

//FOOTER
const footer = {
  background: colors.primary,
  color: colors.white,
  logoBackground: colors.white,
  jetshopBackground: colors.white,
  jetshopColor: colors.font,
  imageAspect: '4:1'
};

//START PAGE
const startPage = {
  headers: {
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight,
    textTransform: 'uppercase',
    fontSize: [
      '__',
      [remCalc(41), remCalc(62), remCalc(82), remCalc(95)],
      typography.header.fontSize[1],
      typography.header.fontSize[2],
      typography.header.fontSize[3],
      typography.header.fontSize[4]
    ]
  },
  iconBar: {
    background: colors.primary,
    color: colors.white
  },
  row: {
    background: {
      primary: colors.backgroundLight,
      secondary: colors.backgroundDark
    },
    color: {
      primary: colors.font,
      secondary: colors.white
    },
    noImage: {
      backgroundLight: colors.backgroundLight,
      backgroundDark: colors.backgroundDark
    }
  },
  readMore: {
    color: colors.font,
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight
  },
  cta: {
    fontSize: button.fontSize,
    color: colors.white,
    background: button.background,
    border: '4px solid ' + button.background,
    fontFamily: button.fontFamily,
    fontWeight: button.fontWeight,
    letterSpacing: button.letterSpacing,
    textTransform: 'none',
    borderRadius: '0px',
    hover: {
      color: colors.white,
      background: colors.font,
      border: '4px solid ' + colors.font
    },
    secondary: {
      color: colors.white,
      background: colors.secondary,
      border: '4px solid ' + colors.secondary,
      hover: {
        color: colors.white,
        background: colors.font,
        border: '4px solid ' + colors.font
      }
    },
    third: {
      color: colors.white,
      background: colors.primaryDark,
      border: '4px solid ' + colors.primaryDark,
      hover: {
        color: colors.white,
        background: colors.primaryDark,
        border: '4px solid ' + colors.primaryDark
      }
    },
    fourth: {
      color: colors.white,
      background: colors.primaryDark,
      border: '4px solid ' + colors.primaryDark,
      hover: {
        color: colors.white,
        background: colors.primaryDark,
        border: '4px solid ' + colors.primaryDark
      }
    }
  }
};

//CATEGORY PAGE
const categoryPage = {
  subcategories: {
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight
  },
  filter: {
    markerColor: colors.buy,
    hoverColor: colors.buy,
    borderColor: colors.border,
    name: {
      tag: 'h4'
    }
  }
};

//PRODUCT GRID
const productGrid = {
  product: {
    imageAspect: '4:3',
    background: '#FFF',
    padding: ['1rem', '1.5rem', '2rem'],
    border: `1px solid ${colors.border}`,
    name: {
      tag: 'h4',
      fontFamily: typography.header.fontFamily,
      fontWeight: weights.normal,
      fontSize: typography.header.fontSize[3][3]
    }
  }
};

const price = {
  fontFamily: typography.header.fontFamily,
  fontWeight: weights.normal,
  normal: {
    color: colors.font,
    fontSize: typography.header.fontSize[4]
  },
  new: {
    color: colors.discount,
    fontSize: typography.header.fontSize[4]
  },
  old: {
    color: colors.greys[5],
    fontSize: typography.header.fontSize[4]
  }
};

//PRODUCT PAGE
const productPage = {
  name: {
    fontFamily: productGrid.product.name.fontFamily,
    fontWeight: weights.bold
  },
  price: {
    fontWeight: weights.bold,
    normal: {
      fontSize: typography.header.fontSize[2]
    },
    new: {
      fontSize: typography.header.fontSize[2]
    },
    old: {
      fontSize: typography.header.fontSize[3]
    }
  },
  imageAspect: '1:1',
  buyButton: {
    background: colors.buy,
    color: colors.white
  },
  usp: {
    fontWeight: weights.normal
  },
  tabs: {
    barActiveColor: colors.primary,
    header: {
      tag: 'h3',
      fontFamily: typography.header.fontFamily,
      fontWeight: typography.header.fontWeight
    }
  },
  productList: {
    headerColor: colors.font,
    subHeaderColor: colors.primary
  }
};

const favourites = {
  colorActive: '#c7661d'
};

//EXPORT COMPLETE THEME
export const bruslettoTheme = {
  config,
  font,
  weights,
  typography,
  colors,
  general,
  button,
  topBar,
  desktopHeader,
  mobileHeader,
  header,
  logo,
  campaignBar,
  footer,
  startPage,
  categoryPage,
  productGrid,
  price,
  productPage,
  favourites
};
